@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'styles/bootstrap';

body {
    margin: 0;
    // font-family: 'Segoe UI' !important;
    font-family: 'Bahnschrift' !important;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f5f5;
    color: #fff;
    background: #f5f5f5;

    ::-webkit-scrollbar {
        display: none;
    }
}

.ant-notification-notice {
    padding: 8px;
    .anticon {
        color: white !important;
    }
    .ant-notification-notice-content {
        .ant-notification-notice-message {
            margin-bottom: 0px;
            color: white;
        }
    }
    .ant-notification-notice-close {
        top: 10px;
    }
    &.ant-notification-notice-error {
        background: #ff4d4f;
    }
    &.ant-notification-notice-success {
        background: #52c41a;
    }
}
