.none {
  display: none !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.text-bold {
  color: black;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@for $i from 1 through 20 {
  .flex-#{$i} {
    flex: #{$i};
  }
  .radius-#{$i} {
    border-radius: #{$i}px !important;
  }
  .m-#{$i * 4} {
    margin: #{$i * 4}px !important;
  }
  .p-#{$i * 4} {
    padding: #{$i * 4}px !important;
  }
  .mt-#{$i * 4} {
    margin-top: #{$i * 4}px !important;
  }
  .mr-#{$i * 4} {
    margin-right: #{$i * 4}px !important;
  }
  .mb-#{$i * 4} {
    margin-bottom: #{$i * 4}px !important;
  }
  .ml-#{$i * 4} {
    margin-left: #{$i * 4}px !important;
  }
  .pt-#{$i * 4} {
    padding-top: #{$i * 4}px !important;
  }
  .pr-#{$i * 4} {
    padding-right: #{$i * 4}px !important;
  }
  .pb-#{$i * 4} {
    padding-bottom: #{$i * 4}px !important;
  }
  .pl-#{$i * 4} {
    padding-left: #{$i * 4}px !important;
  }
  .mx-#{$i * 4} {
    margin-left: #{$i * 4}px !important;
    margin-right: #{$i * 4}px !important;
  }
  .my-#{$i * 4} {
    margin-top: #{$i * 4}px !important;
    margin-bottom: #{$i * 4}px !important;
  }
  .px-#{$i * 4} {
    padding-left: #{$i * 4}px !important;
    padding-right: #{$i * 4}px !important;
  }
  .py-#{$i * 4} {
    padding-top: #{$i * 4}px !important;
    padding-bottom: #{$i * 4}px !important;
  }
}

.flex,
.flex-wrap,
.flex-wrap-reverse,
.align-center,
.align-start,
.align-end,
.justify-between,
.justify-around,
.justify-center,
.justify-start,
.justify-end {
  display: flex;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.pointer {
  cursor: pointer;
}
