@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Regular'), url(../assets/fonts/Segoe\ UI.woff) format('woff');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: normal;
  src: local('Segoe UI Italic'), url(../assets/fonts/Segoe\ UI\ Italic.woff) format('woff');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: bold;
  src: local('Segoe UI Bold'), url(../assets/fonts/Segoe\ UI\ Bold.woff) format('woff');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: bold;
  src: local('Segoe UI Bold Italic'), url(../assets/fonts/Segoe\ UI\ Bold\ Italic.woff) format('woff');
}
